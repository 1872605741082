import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3100744155/src/autobackup-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`Following operations on resources will be disabled if tag-based backup has been enabled:`}</p>
      <ul parentName="blockquote">
        <li parentName="ul">{`enabling/disabling backup,`}</li>
        <li parentName="ul">{`changing policy and setup.`}</li>
      </ul>
    </blockquote>
    <h2 {...{
      "id": "enable-backup"
    }}>{`Enable backup`}</h2>
    <ol>
      <li parentName="ol">{`Choose "Resources" from the navigation bar.
`}<img alt="Resources view" src={require("../../assets/usage/resources-menu.png")} /></li>
      <li parentName="ol">{`Select resources for which you want to enable backup from "Resources List" by checking the checkbox square on the left side of the resource.
`}<img alt="Resources view" src={require("../../assets/usage/resources-list.png")} /></li>
      <li parentName="ol">{`Click "Enable backup" button under "Resources List" title.`}</li>
      <li parentName="ol">{`Click "Confirm" button. Remember that backup will be enabled only for resources that have added a "Setup" and "Policy".
`}<img alt="Resources view" src={require("../../assets/usage/resources-enable-backup-confirm.png")} /></li>
    </ol>
    <h2 {...{
      "id": "disable-backup"
    }}>{`Disable backup`}</h2>
    <ol>
      <li parentName="ol">{`Choose "Resources" from the navigation bar.
`}<img alt="Resources view" src={require("../../assets/usage/resources-menu.png")} /></li>
      <li parentName="ol">{`Select resources for which you want to disable backup from "Resources List" by checking the checkbox square on the left side of the resource.
`}<img alt="Resources view" src={require("../../assets/usage/resources-list-2.png")} /></li>
      <li parentName="ol">{`Click "Disable backup" button under "Resources List" title.`}</li>
      <li parentName="ol">{`Click "Confirm" button. Remember that backup will be disabled only for resources that have backup enabled.
`}<img alt="Resources view" src={require("../../assets/usage/resources-disable-backup-confirm.png")} /></li>
    </ol>
    <h2 {...{
      "id": "change-policy-and-setup"
    }}>{`Change policy and setup`}</h2>
    <ol>
      <li parentName="ol">{`Choose "Resources" from the navigation bar.
`}<img alt="Resources view" src={require("../../assets/usage/resources-menu.png")} /></li>
      <li parentName="ol">{`Select resources for which you want to change policy or setup from "Resources List" by checking the checkbox square on the left side of the resource.
`}<img alt="Resources view" src={require("../../assets/usage/resources-list-3.png")} /></li>
      <li parentName="ol">{`Click "Change policy and/or setup" button under the "Resources List" title.`}</li>
      <li parentName="ol">{`Select policy and/or setup(s) you want to change and click "Continue" button.
`}<img alt="Resources view" src={require("../../assets/usage/resources-list-policy-and-setup-1.png")} /></li>
      <li parentName="ol">{`Apply policies and setups by clicking "Confirm" button.
`}<img alt="Resources view" src={require("../../assets/usage/resources-list-policy-and-setup-2.png")} /></li>
    </ol>
    <h2 {...{
      "id": "trigger-backup"
    }}>{`Trigger backup`}</h2>
    <ol>
      <li parentName="ol">{`Choose "Resources" from the navigation bar.
`}<img alt="Resources view" src={require("../../assets/usage/resources-menu.png")} /></li>
      <li parentName="ol">{`Select resources for which you want to trigger backup from "Resources List" by checking the checkbox square on the left side of the resource.
`}<img alt="Resources view" src={require("../../assets/usage/resources-list-2.png")} /></li>
      <li parentName="ol">{`Click "Trigger backup" button under "Resources List" title.`}</li>
      <li parentName="ol">{`Click "Confirm" button. Remember that backup will be triggered only when all selected resources have enabled backup.
`}<img alt="Resources view" src={require("../../assets/usage/resources-list-trigger-backup.png")} /></li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      